/**
 * Autocomplete to be used on an input field
 *
 ** Json Records have the following structure:
 ** {
 **  "title": "<mark>Dit</mark> is een programmeertaal 1",
 **  "subtitle": "Dit is een subtitel van de programmeertaal", (subtitle is optional)
 **  "value": "Programmeertaal 1",
 **  },
 **/

vl.autocomplete = {};
vl.autocomplete.dress;

(function () {

  /*
  * AC variables
  */
  var acFields    = document.querySelectorAll('[data-autocomplete]'),
      acRecord    = 'data-record',
      dfGeolocationPlaceholder = 'Mijn locatie',
      dfGeolocationLoadingPlaceholder = 'Locatie ophalen...',
      dfGeolocationWarning = 'VL Warning: HTML5 Geolocatie werkt enkel bij een HTTPS connectie.',
      dfGeolocationCallbackFnWarning = 'VL Warning: Specifieer een correcte parameter ("postal_code","city","formatted_address") of een functie voor geolocatie (parameter geolocation.return).',
      dfEmptyStateTitle = 'Geen resultaten';

  vl.autocomplete.dress = function(acField, params) {

    /** AC specific variables **/
    var arrVars = generateAutocomplete(acField),
        inputVal,
        lastVal,
        curIndex      = 0,
        ntChars       = arrVars.ntChars,
        acInput       = arrVars.acInput,
        acId          = arrVars.acId,
        acContent     = arrVars.acContent,
        acList        = arrVars.acList,
        acFocusElems  = acField.querySelectorAll('[data-focus]'),
        acRecords     = acList.querySelectorAll('['+ acRecord +']'),
        acLoader      = acField.querySelector('[data-loader]');

    if(params.noResultsFound == undefined){
      params.noResultsFound = {};
    }

    /** AC specific event handlers **/
    acInput.addEventListener('keydown', acInputKeydownHandler);
    acInput.addEventListener('keyup',   acInputKeyupHandler);
    [].forEach.call(acFocusElems, function(acFocusElem){
      acFocusElem.addEventListener('blur', acFocusElemBlurHandler);
    });

    /*
    * Autocomplete input field keydown event
    */
    function acInputKeydownHandler(e){
      // Stop action on enter, arrow down & up
      switch(e.keyCode){
        case 13: case 38: case 40:
          e.preventDefault();
        break;
      }
    };

    /*
    * Autocomplete input field keyup event
    */
    function acInputKeyupHandler(e){
      inputVal = acInput.value;
      switch(e.keyCode){
        // Used to hijack the "enter" key when selecting an element in the dropdown
        case 13:
          e.preventDefault();
          var target = acList.querySelector('[data-record][data-index="'+ curIndex +'"]');
          // Trigger click when it's a geolocation record
          if(target){
            if(target.hasAttribute('data-geolocation'))
              target.click();
          }
          setAcContentState(acField, "hide");
        break;

        // "Esc" key
        case 27:
          e.preventDefault();
          acInput.setAttribute('aria-expanded', false);
          setAcContentState(acField, "hide");
        break;

        // "arrow down" key
        case 40:
          e.preventDefault();
          moveFocus("down");
        break;

        // "arrow up" key
        case 38:
          e.preventDefault();
          moveFocus("up");
        break;

        default:
          if(this.value !== lastVal){ // Check if value is different
            if(this.value.length >= ntChars){ // Check if value is longer than the min amount of characters
              curIndex = 0;

              setPreloader(acLoader, acField, true); // Start preloader
              var obj = { // Retrieve data
                searchStr: acInput.value,
                callbackFn: cb
              };
              params.callbackFn(acField, obj);
              function cb(jsonData){
                setPreloader(acLoader, acField, false); // Stop preloader records from data
                if(jsonData !== null && typeof jsonData === 'object'){ // Generate records from data
                  generateAutocompleteRecords(acField, jsonData, params);
                }else if(jsonData == false){
                  generateEmptyRecord(acField, params);
                }
              }

            }else{
              setAcContentState(acField, "hide");
            }
          }
          // Check if value is changed
          lastVal = this.value;
        break;
      }
    };

    /*
    * moveFocus()
    * @param : direction (string)
    */
    function moveFocus(direction){
      if(acInput.getAttribute('aria-expanded') == "true"){
        var countElems = acList.querySelectorAll('['+ acRecord +']').length;

        switch(direction){
          case "down":
            if(curIndex < countElems){
              curIndex++;
              setValue();
            }
          break;

          case "up":
            if(curIndex > 1){
              curIndex--;
              setValue();
            }
          break;
        }

        function setValue(){
          [].forEach.call(acList.querySelectorAll('[data-record]'), function(item){ removeClass(item, 'autocomplete__cta--focus') });
          var target = acList.querySelector('[data-record][data-index="'+ curIndex +'"]');
          addClass(target, 'autocomplete__cta--focus');
          setInputfieldValue(acField, acInput, target.getAttribute('data-value'));
          target.focus();
          acInput.focus();
        }
      }
    }

    /*
    * acFocusElemBlurHandler();
    * Used to check the focus state and close the autocomplete when focus is outside of the element
    */
    function acFocusElemBlurHandler(e){
      window.setTimeout(function(){
        var parent = document.activeElement.closest('[data-autocomplete][data-id="' + acId + '"]');
        if(parent === null){
          setVisibilityAttributes(acContent, false, true);
          acInput.setAttribute('aria-expanded', false);
        }
      }, 1);
    };

  };

  /*
  * generateAutocompleteRecords();
  * @param acField: Autocomplete div (.js-select)
  * @param data: JSON object {*title: "", subtitle: "", *value: ""}
  */
  function generateAutocompleteRecords(acField, data, params) {

    if(data !== null){
      var ntChars       = acField.getAttribute('[data-input]'),
          acInput       = acField.querySelector('[data-input]'),
          acContent     = acField.querySelector('[data-content]'),
          acList        = acField.querySelector('[data-records]'),
          acLoader      = acField.querySelector('[data-loader]');

      /* #1 Remove current children if existent */
      var acList = acField.querySelector('[data-records]');
      while (acList.firstChild) {
          acList.removeChild(acList.firstChild);
      }

      /* #2 Generate children based on the data */
      var c = 1;


      if(params.hasGeolocation){
        ("https:" !== document.location.protocol ? console.warn(dfGeolocationWarning) : _generateGeolocationRecord() );
        c++;
      }

      function _generateGeolocationRecord(){

        if('geolocation' in navigator){
          (params.geolocation.placeholder == undefined ? params.geolocation.placeholder = dfGeolocationPlaceholder : null);

          var acRecord = document.createElement('li');
          addClass(acRecord, 'autocomplete__item');
          acRecord.setAttribute('role','option');
          acRecord.innerHTML = '<a class="autocomplete__cta" href="#" tabindex="-1" data-index="' + c + '" data-record data-focus data-geolocation>' +
                                  '<span class="autocomplete__cta__title autocomplete__cta__title--location">' + params.geolocation.placeholder + '</span>' +
                               '</a>';

          acRecord.addEventListener('click', function(e){
            e.preventDefault();
            _requestLocation();
          });

          acRecord.addEventListener('keydown', function(e){
            e.preventDefault();
            (e.keyCode == 13 ? _requestLocation() : null);
          });

          acList.appendChild(acRecord);
        }

        function _requestLocation(){

          var options = {
            enableHighAccuracy: false, // enableHighAccuracy = should the device take extra time or power to return a really accurate result, or should it give you the quick (but less accurate) answer?
            timeout: 5000, // timeout = how long does the device have, in milliseconds to return a result?
            maximumAge: 0 // maximumAge = maximum age for a possible previously-cached position. 0 = must return the current position, not a prior cached position
          };

          // Enable preloader, close the autocomplete
          setPreloader(acLoader, acField, true);
          var prevPlaceholder = acInput.getAttribute('placeholder');
          setVisibilityAttributes(acContent, false, true);
          acInput.setAttribute('aria-expanded', false);
          acInput.setAttribute('disabled', true);
          acInput.setAttribute('placeholder', dfGeolocationLoadingPlaceholder);
          acInput.value = '';

          navigator.geolocation.getCurrentPosition(success, error, options);

          function success(pos){
            setPreloader(acLoader, acField, false);

            var geocoords = {
              lng: pos.coords.longitude,
              lat: pos.coords.latitude
            };
            if(params.geolocation.return !== undefined){
              switch(params.geolocation.return){

                case "postal_code":
                  getJSON("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + geocoords.lat + "," + geocoords.lng + "&sensor=true",
                  function(err, data) {
                    if(data.status === "OK"){
                      data.results.forEach(function(element){
                        element.address_components.forEach(function(element2){
                          element2.types.forEach(function(element3){
                            switch(element3){
                              case 'postal_code':
                                geolocationCallbackReturn(element2.long_name);
                                return;
                              break;
                            }
                          });
                        });
                      });
                    }
                    else
                      geolocationCallbackReturn(false);
                  });
                break;

                case "city":
                  getJSON("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + geocoords.lat + "," + geocoords.lng + "&sensor=true",
                  function(err, data) {
                    if(data.status === "OK"){
                      data.results.forEach(function(element){
                        element.address_components.forEach(function(element2){
                          element2.types.forEach(function(element3){
                            switch(element3){
                              case 'locality':
                                geolocationCallbackReturn(element2.long_name);
                                return;
                              break;
                            }
                          });
                        });
                      });
                    }
                    else
                      geolocationCallbackReturn(false);
                  });
                break;

                case "formatted_address":
                  getJSON("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + geocoords.lat + "," + geocoords.lng + "&sensor=true",
                  function(err, data) {
                    if(data.status === "OK")
                      geolocationCallbackReturn(data.results[0].formatted_address);
                    else
                      geolocationCallbackReturn(false);
                  });
                break;

                default:
                  // Custom functie
                  if (typeof params.geolocation.return === "function")
                    params.geolocation.return(geocoords, geolocationCallbackReturn);
                  else
                    console.warn(dfGeolocationCallbackFnWarning);
              }
            }
            else{
              console.warn(dfGeolocationCallbackFnWarning);
              acInput.removeAttribute('disabled');
              acInput.focus();
              acInput.setAttribute('placeholder', prevPlaceholder);
            }

            function geolocationCallbackReturn(str){
              if(str !== false){
                acInput.value = str;
              }
              acInput.removeAttribute('disabled');
              acInput.focus();
              acInput.setAttribute('placeholder', prevPlaceholder);
            }
          }

          function error(err){
            setPreloader(acLoader, acField, false);
            acInput.removeAttribute('disabled');
            acInput.focus();
            acInput.setAttribute('placeholder', prevPlaceholder);

            return false;
          }
        }
      }

      _generateRecords();

      function _generateRecords(){

        [].forEach.call(data, generateRecord);
        function generateRecord(obj){
          // Generate list item
          var acRecord = document.createElement('li');
          addClass(acRecord, 'autocomplete__item');
          acRecord.setAttribute('role','option');

          // Append html to the record
          if(typeof obj.subtitle !== "undefined"){
          acRecord.innerHTML = '<a class="autocomplete__cta" href="#" tabindex="-1" data-index="' + c + '" data-record data-focus data-value="' + obj.value + '">' +
                                  '<span class="autocomplete__cta__sub">' + obj.subtitle + '</span>' +
                                  '<span class="autocomplete__cta__title">' + obj.title + '</span>' +
                               '</a>';
          }else{
            acRecord.innerHTML = '<a class="autocomplete__cta" href="#" tabindex="-1" data-index="' + c + '" data-record data-focus data-value="' + obj.value + '">' +
                                  '<span class="autocomplete__cta__title">' + obj.title + '</span>' +
                                 '</a>';
          }

          /* #2.1 Click event for child */
          acRecord.addEventListener('click', function(e){
            e.preventDefault();
            var rec = acRecord.querySelector('[data-record]');
            setInputfieldValue(acField, acInput, rec.getAttribute('data-value'));
            setVisibilityAttributes(acContent, false, true);
            acInput.setAttribute('aria-expanded', false);
          });

          acList.appendChild(acRecord);
          c++;
        }
      }

      /* #3 Show content */
      setVisibilityAttributes(acContent, true, false);
      acInput.setAttribute('aria-expanded', "true");
    }
  };


  /*
  * generateEmptyRecord();
  * @param acField: Autocomplete div (.js-select)
  */
  function generateEmptyRecord(acField, params) {
      var acContent     = acField.querySelector('[data-content]'),
          acInput       = acField.querySelector('[data-input]'),
          acList        = acField.querySelector('[data-records]');

      while (acList.firstChild) {
          acList.removeChild(acList.firstChild);
      }

      if(!params.noResultsFound){
        setVisibilityAttributes(acContent, false, true);
        acInput.setAttribute('aria-expanded', "false");
        return;
      }else
        if(params.noResultsFound.title == undefined)
          params.noResultsFound.title = dfEmptyStateTitle;

      var acRecord = document.createElement('li');
          addClass(acRecord, 'autocomplete__item');
          acRecord.setAttribute('role','option');

          if(params.noResultsFound.subtitle == undefined){
            acRecord.innerHTML = '<div class="autocomplete__alert">' +
                                    '<span class="autocomplete__alert__title">' + params.noResultsFound.title + '</span>' +
                                 '</div>';
          }else{
            acRecord.innerHTML = '<div class="autocomplete__alert">' +
                                    '<span class="autocomplete__alert__title">' + params.noResultsFound.title + '</span>' +
                                    '<span class="autocomplete__alert__subtitle">' + params.noResultsFound.subtitle + '</span>' +
                                 '</div>';
          }
      acList.appendChild(acRecord);

      /* #3 Show content */
      setVisibilityAttributes(acContent, true, false);
      acInput.setAttribute('aria-expanded', "true");
  };

  /*
  * setVisibilityAttributes()
  * Setting data attributes & aria tags
  * @param field        (DOM element)
  * @param dataShow     (bool)
  * @param ariaHidden   (bool)
  */
  function setVisibilityAttributes(field, dataShow, ariaHidden){
    field.setAttribute('data-show',   dataShow);
    field.setAttribute('aria-hidden', ariaHidden);
  };

  /*
  * setPreloader()
  * Showing/hiding the preloader on the autocomplete input field
  * @param acLoader   (DOM element)
  * @param acField    (DOM element)
  * @param isloading  (bool) sets/unsets preloader in autocomplete input field
  */
  function setPreloader(acLoader, acField, isLoading){
    (isLoading ? setVisibilityAttributes(acLoader, true, false) : setVisibilityAttributes(acLoader, false, true));
    acField.setAttribute('data-loading', isLoading);
  };

  /*
  * setAcState
  * @param acField    (DOM element)
  * @param acState    (bool)
  */
  function setAcContentState(acField, acState){
    var acContent = acField.querySelector('[data-content]');
    switch(acState){
      case "show": setVisibilityAttributes(acContent, true, false); break;
      case "hide": setVisibilityAttributes(acContent, false, true); break;
    }
  };

  /*
  * setInputfieldValue
  * @param acInput    (DOM element)
  * @param value        (string)
  */
  function setInputfieldValue(acField, acInput, value){
    // Set value
    acInput.value = value;
    fireEvent(acInput, 'vl.autocomplete.hasChanged');
  };

  /*
  * generateAutocomplete()
  * @param acField  (DOM element)
  */
  function generateAutocomplete(acField){

    var acId = uniqueId();
    acField.setAttribute('data-id', acId);

    var arr, ntChars = detectCharacterCount(acField);
    var acInput = setAcInput(acField);
    var acLoader = setAcLoader(acField);
    var acContent = setAcContent(acField, acInput);
    var acListWrapper = setAcListWrapper(acContent);
    var acList = setAcList(acListWrapper);

      /* detect charcount */
    function detectCharacterCount(acField){
      if(acField.hasAttribute('data-min-chars') && isNumeric(acField.getAttribute('data-min-chars'))){
        return acField.getAttribute('data-min-chars');
      }else{
        return 3;
      }
    }

    /* modify the given input field */
    function setAcInput(acField){
      var acInput = acField.querySelector('input');
      (!acInput.hasAttribute('id') ? acInput.setAttribute('id', 'autocomplete-input-' + uniqueId()) : null);
      acInput.setAttribute('aria-expanded', "false");
      acInput.setAttribute('data-focus', '');
      acInput.setAttribute('data-input', '');
      acInput.setAttribute('autocapitalize', 'off');
      acInput.setAttribute('spellcheck', 'off');
      acInput.setAttribute('autocomplete', 'off');
      acInput.setAttribute('aria-autocomplete', 'list');
      acInput.setAttribute('aria-owns', 'autocomplete-' + acId);
      acInput.setAttribute('aria-controls', 'autocomplete-' + acId);
      acInput.setAttribute('aria-haspopup', 'listbox');

      return acInput;
    }

    function setAcLoader(acField){
        var acLoader = document.createElement("div");
        addClass(acLoader, 'autocomplete__loader');
        acLoader.setAttribute('data-show','false');
        acLoader.setAttribute('data-loader','');
        acLoader.setAttribute('aria-hidden',true);
        acField.appendChild(acLoader);

        return acLoader;
    }

    /* generate the accontent field */
    function setAcContent(acField, acInput){

      var acContent = document.createElement('div');
      addClass(acContent, 'autocomplete');
      acContent.setAttribute('data-content', '');
      acContent.setAttribute('aria-hidden', true);
      acContent.setAttribute('data-show', false);
      acContent.setAttribute('aria-labelledby', acInput.getAttribute('id'));
      acContent.setAttribute('id', 'autocomplete-' + acId);
      acField.appendChild(acContent);

      return acContent;
    }

    /* generate the aclistwrapper field */
    function setAcListWrapper(acContent){

      var acListWrapper = document.createElement('div');
      addClass(acListWrapper, 'autocomplete__list-wrapper');
      acContent.appendChild(acListWrapper);

      return acListWrapper;
    }

    /* generate the aclistwrapper field */
    function setAcList(acListWrapper){

      var acList = document.createElement('ul');
      addClass(acList, 'autocomplete__list');
      acList.setAttribute('data-records', '');
      acList.setAttribute('role','listbox');
      acListWrapper.appendChild(acList);

      return acList;
    }

    return {"acId": acId, "acLoader": acLoader, "ntChars": ntChars, "acInput": acInput, "acContent": acContent, "acListWrapper": acListWrapper, "acList": acList};
  };

})();

