/**
 * Progressively enhance a select field
 */

// create global vl select function
vl.select = {};
vl.select.dress;

(function () {

  var selectFields                      = document.querySelectorAll('[data-select]'),
      selectContentListItemActiveState  = 'select__cta--active',
      selectContentListItemFocusState   = 'select__cta--focus',
      selectContentListItemHiddenState  = 'select__cta--hidden',
      lastSelectId, lastContainer,
      dfNoResultsFound                  = 'Geen resultaten';

  vl.select.dress = function(selectField, params) {
    /*
    * Variables needed in Generate selects basted on original <select> elements
    */
    var arr                       = generateSelect(selectField),
        arrOptions                = arr[0],
        selectId                  = arr[1],
        selectContainer           = arr[2],
        selectList                = arr[3],
        originalSelectOption      = null,
        activeArrOptions          = arrOptions,
        selectDummyInput          = selectContainer.querySelector('.js-select__input'),
        selectContent             = selectContainer.querySelector('[data-content]'),
        selectContentInput        = selectContent.querySelector('[data-input]'),
        selectContentList         = selectContent.querySelector('[data-records]'),
        selectFocusElems          = selectContainer.querySelectorAll('[data-focus]'),
        selectForm                = selectField.closest('[data-validate-form]') || null;

    /*
    * Events in select element
    */
    (selectContentInput ? selectContentInput.addEventListener('keyup', selectContentInputKeyUpHandler) : null );
    (selectContentInput ? selectContentInput.addEventListener('keydown', selectContentInputKeyDownHandler) : null );
    (selectContentInput ? selectContentInput.addEventListener('blur', selectContentInputBlurHandler) : null );
    (selectContainer ? selectContainer.addEventListener('keydown', selectContainerKeyDownEventHandler) : null );
    (selectDummyInput ? selectDummyInput.addEventListener('click', selectDummyInputClickEventHandler) : null );

    document.addEventListener('click', function(e){
      setSelectState(false);
    });

    selectContainer.addEventListener('click', function(e){
      e.stopPropagation();
      e.preventDefault();
    });

    /*
    * Eventhandler | selectContentInput keyDown
    */
    function selectContentInputKeyDownHandler(e){
      if(e.shiftKey && e.keyCode == 9){
        e.preventDefault();
        return false;
      }

      switch(e.keyCode){
        case 27: case 9:
          e.preventDefault();
          return false;
        break;
      }
    };

    /*
    * Eventhandler | selectContentInput keyUp
    */
    function selectContentInputKeyUpHandler(e){
      e.preventDefault();
      if(e.shiftKey && e.keyCode == 9){ // Tab + Shift
        setSelectState(false);
        selectDummyInput.focus();
        return;
      }
      var curOption, curOptionIndex;
      (activeArrOptions.length <= 0 ? _resetOptions() : _setCurrentOption() );
      switch(e.keyCode){
        case 27: case 9: // "Tab key" & "Esc key"
          setSelectState(false);
          selectDummyInput.focus();
        break;
        case 8: // "Backspace key"
          _resetOptions();
          _keyDefaultHandler();
        break;
        case 32: // "Space key"
          (selectContent.getAttribute('data-show') !== "true" ? selectDummyInput.click() : null);
        break;
        case 13: // "Enter key"
          if(selectDummyInput.getAttribute('aria-expanded') !== "false" ){
            curOption.click();
            selectDummyInput.focus();
          }
          if(selectContentInput !== null){
            _keyDefaultHandler(selectContentList.querySelector('[data-selected="true"]'));
          };
        break;
        case 38: // "Arrow up key"
            (activeArrOptions.length > 0 ? _keyUpHandler() : null );
        break;
        case 40: // "Arrow down key"
            (activeArrOptions.length > 0 ? _keyDownHandler() : null);
        break;
        default:
          (selectDummyInput.getAttribute('aria-expanded') !== "false" ? _keyDefaultHandler() : null);
        break;
      }

      if(activeArrOptions.length > 0){
        _setCurrentOption();
      }

      function _keyUpHandler(){
        e.preventDefault();

        if(selectContent.getAttribute('data-show') !== "true"){
          // Tonen bij arrow down en index één verhogen zodat je op dezelfde positie zit bij het openen
          setSelectState(true);
          curOptionIndex++;
        }

        if(curOptionIndex > 0){
          curOptionIndex--;
          curOption.removeAttribute('data-selected');
          var el = selectContentList.querySelector('[data-record][data-index="'+curOptionIndex+'"]');
          el.setAttribute('data-selected','true');
          el.focus();
          if(selectContentInput !== null){
            selectContentInput.focus();
          }else{
            selectDummyInput.focus();
          }
        }
      }

      function _keyDownHandler(){
        e.preventDefault();

        if(selectContent.getAttribute('data-show') !== "true"){
          // Tonen bij arrow down en index één minderen zodat je op dezelfde positie zit bij het openen
          setSelectState(true);
          curOptionIndex--;
        }

        if(curOptionIndex < (activeArrOptions.length - 1)){
          curOptionIndex++;
          curOption.removeAttribute('data-selected');
          var el = selectContentList.querySelector('[data-record][data-index="'+curOptionIndex+'"]');
          el.setAttribute('data-selected','true');
          el.focus();
          if(selectContentInput !== null){
            selectContentInput.focus();
          }else{
            selectDummyInput.focus();
          }
        }
      }

      function _keyDefaultHandler(selectedEl){
        selectedEl = selectedEl || null;
        if(selectContentInput !== null){
          var val = selectContentInput.value, first; activeArrOptions = []; // Clear activeArrOptions
          if(params !== undefined && params.callbackFn !== null && params.callbackFn !== undefined){ // If dynamic load is enabled
            var obj = {
              searchStr: val,
              callbackFn: cb
            }
            var jsonData = params.callbackFn(selectField, obj);
            function cb(jsonData){
              if(jsonData !== null && typeof jsonData === 'object'){
                _repopuplateSelect(jsonData);
              }else if(jsonData === false){
                _clearSelectOptions();
                setNoResultsFoundElement("show", selectField, selectContentList, params);
              }
            }
          }else{
            for(var item, i = 0; item = arrOptions[i]; i++) { // If dynamic load isn't enabled
              var el = selectContentList.querySelector('[data-record][data-label="'+item+'"]');
              // Set visibility hidden of all items & Remove old index of all items & Remove old focus
              el.setAttribute('data-show', 'false');
              el.removeAttribute('data-index');
              el.removeAttribute('data-selected');

              // If substring is present in string show item and push to array
              if(item.toLowerCase().indexOf(val.toLowerCase() ) > -1){
                el.setAttribute('data-show', 'true');
                activeArrOptions.push(item);
              }
            }

            if(activeArrOptions.length > 0){ // Wel resultaten
              setNoResultsFoundElement("hide", selectField, selectContentList, params);
              for(var opt, i = 0; opt = activeArrOptions[i]; i++) {
                selectContentList.querySelector('[data-record][data-label="'+opt+'"]').setAttribute('data-index', i);
              }
              // Set focus on first element
              if(selectedEl !== null) {
                selectedEl.setAttribute('data-selected','true');
                window.setTimeout(function(){
                  selectedEl.focus();
                  selectContentInput.focus();
                }, 1);
              } else {
                var el = selectContentList.querySelector('[data-record][data-index="0"]');
                (el !== null ? el.setAttribute('data-selected','true') : null );
              }
            }else{ // Wel resultaten
              setNoResultsFoundElement("show", selectField, selectContentList, params);
            }

            var optgroups = selectContentList.querySelectorAll('.select__group');
            [].forEach.call(optgroups, function(optgroup){
              var items = optgroup.querySelectorAll('.select__cta[data-show="true"]');
              (!items.length ? optgroup.style.display = "none" : optgroup.style.display = "block" );
            });
          }
        }
      }

      function _setCurrentOption(){
        curOption = selectContentList.querySelector('[data-record][data-selected="true"]');

        (curOption == null ? curOption = selectContentList.querySelector('[data-record][data-index="0"]') : null);
        curOptionIndex = curOption.getAttribute('data-index');
      }

      function _resetOptions(){
        for(var item, i = 0; item = arrOptions[i]; i++) {
          var el = selectContentList.querySelector('[data-record][data-label="'+item+'"]');
              el.removeAttribute('data-selected');
              el.setAttribute('data-show', 'true');
              el.setAttribute('data-index', i);
        }

        if(selectContentList.querySelector('[data-record][data-index="0"]') !== null)
          selectContentList.querySelector('[data-record][data-index="0"]').setAttribute('data-selected','true');

        activeArrOptions = arrOptions;
      }

      function _repopuplateSelect(jsonData){

        _clearSelectOptions(selectField);

        // Loop through the jsonData string
        [].forEach.call(jsonData, function(el){
          switch(el.type){
            case "option":
              var option = document.createElement("option");

              option.text = el.label;
              option.setAttribute('value', el.value);
              selectField.add(option);

              while (selectList.hasChildNodes())
                selectList.removeChild(selectList.lastChild);

                    // Generate list items based on options in real select
                    var i = 0;
                    [].forEach.call(selectField.options, function(opt){
                      var value = opt.value;
                      var label = opt.innerHTML;

                      // If item has "data-placeholder" it's used as a placeholder item
                      if(opt.hasAttribute('data-placeholder')){
                        selectDummyInput.innerHTML = label;
                      }else{
                        // SelectOption
                        var selectOption = document.createElement('div');
                        addClass(selectOption, 'select__item');

                        // Titel (button wrapper)
                        var selectOptionButton = document.createElement('button');
                            addClass(selectOptionButton, 'select__cta');

                            var closestOptGroup = opt.closest('optgroup');

                            selectOptionButton.setAttribute('type', 'button');
                            selectOptionButton.setAttribute('data-index', i);
                            selectOptionButton.setAttribute('data-value', value);
                            selectOptionButton.setAttribute('data-label', label);
                            selectOptionButton.setAttribute('data-record','');
                            selectOptionButton.setAttribute('data-focus', '');
                            selectOptionButton.setAttribute('role', 'option');
                            selectOptionButton.setAttribute('tabindex','-1');

                            // selected state for first elem
                            if(i == 0){
                              selectOptionButton.setAttribute('data-selected', 'true');
                              addClass(selectOptionButton, selectContentListItemActiveState);
                            }

                            // Titel (span wrapper)
                            var selectOptionTitleSpan = document.createElement("span");
                                addClass(selectOptionTitleSpan, 'select__cta__title');
                                selectOptionTitleSpan.innerHTML = label;

                                // Appends
                                selectOptionButton.appendChild(selectOptionTitleSpan);
                            selectOption.appendChild(selectOptionButton);

                        selectList.appendChild(selectOption);

                        // Add to arrOptions array
                        arrOptions.push(label);
                        i++;
                      }
                    });
            break;

            // case "optiongroup":

            // break;
          }

          activeArrOptions = arrOptions;
          setRecordEvents();
        });
      }

      function _clearSelectOptions(){
        // Clear arrOptions
        arrOptions = [];

        var c;
        for(c = selectField.options.length - 1 ; c >= 0 ; c--){
            selectField.remove(c);
        }

        // Remove nodes in selectList
        while (selectList.hasChildNodes())
          selectList.removeChild(selectList.lastChild);
      }
    };

    /*
    * Eventhandler | selectContainer Blur
    */
    function selectContentInputBlurHandler(e){
      window.setTimeout(function(){
        if(selectForm !== null){
          validate = new fiveForms({id: selectForm.getAttribute('id')});
          var obj = { "obj": selectField, "name": selectField.name, "type": selectField.nodeName.toLowerCase() };
          var vf = validate.validateField(obj);

          if(hasClass(document.activeElement,'select__cta')){
            removeClass(selectDummyInput, 'error');
            removeClass(selectField, selectField.getAttribute('data-error-class'));
            if (selectField.hasAttribute('data-error-message') && selectField.hasAttribute('data-error-placeholder') && document.querySelector('[data-error-id=' + selectField.getAttribute('data-error-placeholder') + ']')) {
              (document.querySelector('[data-error-id=' + selectField.getAttribute('data-error-placeholder') + ']')).innerHTML = '';
            }
            // add error data-attribute
            selectField.setAttribute('data-has-error', false);
            return;
          }

          if(vf.error){
            _addError();
          }else{
            _removeError();
          }
        }


        function _addError(){
          addClass(selectDummyInput, 'error');
            addClass(vf.obj, vf.obj.getAttribute('data-error-class'));
            // show form error if it is defined
            if (vf.obj.hasAttribute('data-error-message') && vf.obj.hasAttribute('data-error-placeholder') && document.querySelector('[data-error-id=' + vf.obj.getAttribute('data-error-placeholder') + ']')) {
              (document.querySelector('[data-error-id=' + vf.obj.getAttribute('data-error-placeholder') + ']')).innerHTML = vf.obj.getAttribute('data-error-message');
            }
            // add error data-attribute
            vf.obj.setAttribute('data-has-error', true);
        }

        function _removeError(){
          removeClass(selectDummyInput, 'error');
            removeClass(vf.obj, vf.obj.getAttribute('data-error-class'));
            if (vf.obj.hasAttribute('data-error-message') && vf.obj.hasAttribute('data-error-placeholder') && document.querySelector('[data-error-id=' + vf.obj.getAttribute('data-error-placeholder') + ']')) {
              (document.querySelector('[data-error-id=' + vf.obj.getAttribute('data-error-placeholder') + ']')).innerHTML = '';
            }
            // add error data-attribute
            vf.obj.setAttribute('data-has-error', false);
        }
      }, 10);
    };


    /*
    * Eventhandler | selectContainer keyDown
    */
    function selectContainerKeyDownEventHandler(e){
      switch(e.keyCode){
        case 13: case 38: case 40:
        e.preventDefault();
        break;
      }
    };

    /*
    * Eventhandler | selectDummyInput Click
    */
    function selectDummyInputClickEventHandler(e){
      if(selectContent.getAttribute('data-show') === "false"){
        // Show select
        setSelectState(true);
        // Set focus on search if present
        selectContentInput.focus();
        // Set selected option or first option active
        if(originalSelectOption !== null){
          selectContentList.querySelector('[data-record][data-label="'+originalSelectOption+'"]').setAttribute('data-selected', 'true');
        }else{
          if(selectContentList.querySelector('[data-record][data-index="0"]') !== null)
            selectContentList.querySelector('[data-record][data-index="0"]').setAttribute('data-selected','true');
        }
      }
      else{
        setSelectState(false);
        selectDummyInput.focus();
      }
    };

    /*
    * Loop through dynamically generated records
    */
    setRecordEvents();
    function setRecordEvents(){
      var selectContentListItems = selectContent.querySelectorAll('[data-record]');

      [].forEach.call(selectContentListItems, function(item){
        item.addEventListener('click', function(e){
          var lbl = item.getAttribute('data-label');
          var val = item.getAttribute('data-value');

          // Set selected state to original select
          originalSelectOption = setOriginalSelectFieldOption(selectId, val);

          // Set label in dummy input
          selectDummyInput.innerHTML = lbl;

          // Close select
          setSelectState(false);
          selectDummyInput.focus();

          // Remove active class of alle elements
          [].forEach.call(selectContentListItems, function(item2){
            removeClass(item2, selectContentListItemActiveState);
            item2.removeAttribute('data-selected');
          });

          // Add active class to selected element
          addClass(item, selectContentListItemActiveState);

          // Remove query in select input
          if(selectContentInput !== null)
            selectContentInput.value = "";
        });
      });
    };


    /*
    * setSelectState()
    * Setting the general data attributes & aria tags of the generated select
    */
    function setSelectState(isShown){
      if(isShown){
        var dataShow = true,
            ariaHidden = false,
            ariaExpanded = true;
      }else{
        var dataShow = false,
            ariaHidden = true,
            ariaExpanded = false;
      };

      selectContent.setAttribute('data-show', dataShow);
      selectContent.setAttribute('aria-hidden', ariaHidden);
      selectDummyInput.setAttribute('aria-expanded', ariaExpanded);
    };
  };

  /*
  * setDisabledState()
  * Sets disabled state of both native and generated select
  * @param selectField(object)
  * @param state(boolean)
  */
  vl.select.setDisabledState = function(selectField, state) {
    var selectContainer   = selectField.closest('.js-select');
    var selectDummyInput  = selectContainer.querySelector('.js-select__input');

    if(state){
      selectField.setAttribute('disabled', state);
      selectDummyInput.setAttribute('disabled', state);
    }else{
      selectField.removeAttribute('disabled');
      selectDummyInput.removeAttribute('disabled');
    }
  };

  /*
  * Loop through all select fields
  */
  [].forEach.call(selectFields, function(selectField) {
    vl.select.dress(selectField);
  });

  /*
  * setVisibilityAttributes()
  * Setting the general data attributes & aria tags
  */
  function setVisibilityAttributes(field, dataShow, ariaHidden){
      field.setAttribute('data-show',   dataShow);
      field.setAttribute('aria-hidden', ariaHidden);
  };

  /*
  * generateSelect()
  * Generating the ehanced select
  */
  function generateSelect(selectField){
    // Hide normal select field
    addClass(selectField, 'u-visually-hidden');
    selectField.setAttribute('aria-hidden','true');
    selectField.setAttribute('tabindex','-1');

    // Variables
    var arr = [], uniqId = uniqueId();

    // Set selectContainer
    var selectContainer = selectField.closest('.js-select');

    // Get data-id or generate one
    if(selectField.hasAttribute('data-id')){
      uniqId = selectField.getAttribute('data-id');
      selectContainer.setAttribute('data-id', uniqId);
    }else{
      selectContainer.setAttribute('data-id', uniqId);
    }

    // Fake select field
    var selectDummyInput = document.createElement("button");
        selectDummyInput.setAttribute('type','button');
        selectDummyInput.setAttribute('data-focus', '');
        selectDummyInput.setAttribute('id', uniqId);
        selectDummyInput.setAttribute('aria-haspopup', 'true');
        selectDummyInput.setAttribute('aria-expanded', 'false');
        addClass(selectDummyInput, 'js-select__input');
        if(selectField.hasAttribute('disabled') && selectField.getAttribute('disabled') !== "false")
          selectDummyInput.setAttribute('disabled','true');

        selectContainer.insertBefore(selectDummyInput, selectContainer.firstChild);


    // Select Wrapper
    var selectWrapper = document.createElement("div");
        addClass(selectWrapper, 'select__wrapper');
        selectWrapper.setAttribute('data-content','');
        selectWrapper.setAttribute('aria-labelledby',uniqId);
        setVisibilityAttributes(selectWrapper, false, true);

        selectContainer.appendChild(selectWrapper);

        // Select Form Wrapper

        var selectForm = document.createElement("div");
            addClass(selectForm, 'select__form');

            selectWrapper.appendChild(selectForm);

            // Select Form Input
            var selectFormInput = document.createElement('input');
                selectFormInput.setAttribute('type','text');
                selectFormInput.setAttribute('autocomplete','off');
                addClass(selectFormInput, 'input-field');
                addClass(selectFormInput, 'input-field--block');
                selectFormInput.setAttribute('data-input','');
                selectFormInput.setAttribute('data-focus', '');
                selectFormInput.setAttribute('value','');
                selectFormInput.setAttribute('tabindex','-1');
                selectFormInput.setAttribute('aria-describedby', 'selectFormInputDescription' + uniqId);
                selectFormInput.setAttribute('aria-haspopup', 'listbox"');

                selectForm.appendChild(selectFormInput);

            var selectFormInputDescription = document.createElement('span');
                selectFormInputDescription.setAttribute('id','selectFormInputDescription' + uniqId);
                selectFormInputDescription.innerHTML = "U bevindt zich in de zoekfunctie van een dropdown menu in een formulier. Navigeer door de opties met ctrl + alt + pijltjes en selecteer met enter. Gebruik escape om de dropdown te sluiten.";
                addClass(selectFormInputDescription, 'u-visually-hidden');

                selectForm.appendChild(selectFormInputDescription);


        // Select List Wrapper
        var selectListWappper = document.createElement('div');
                addClass(selectListWappper,'select__list-wrapper');
                selectListWappper.setAttribute('role','listbox');

                selectWrapper.appendChild(selectListWappper);

                // Select List
                var selectList = document.createElement('section');
                    addClass(selectList, 'select__list');
                    selectList.setAttribute('data-records','');

                    selectListWappper.appendChild(selectList);

                    // Generate option groups based on optgroups in real select
                    var optgroups = selectField.querySelectorAll('optgroup');
                    if(optgroups.length > 0){
                      [].forEach.call(optgroups, function(optgroup){
                        var label = optgroup.getAttribute('label');
                        var selectOptionGroupWrapper = document.createElement('section');
                        addClass(selectOptionGroupWrapper, 'select__group');
                        selectOptionGroupWrapper.setAttribute('data-label', label);
                        selectOptionGroupWrapper.setAttribute('role', 'group');
                        selectList.appendChild(selectOptionGroupWrapper);

                        var selectOptionGroupTitle = document.createElement('h1');
                        selectOptionGroupTitle.innerHTML = label;

                        selectOptionGroupWrapper.appendChild(selectOptionGroupTitle);
                      });
                    }

                    // Generate list items based on options in real select
                    var i = 0;
                    [].forEach.call(selectField.options, function(opt){
                      var value = opt.value;
                      var label = opt.innerHTML;

                      // If item has "data-placeholder" it's used as a placeholder item
                      if(opt.hasAttribute('data-placeholder')){
                        selectDummyInput.innerHTML = label;
                      }else{
                        // SelectOption
                        var selectOption = document.createElement('div');
                        addClass(selectOption, 'select__item');

                        // Titel (button wrapper)
                        var selectOptionButton = document.createElement('button');
                            addClass(selectOptionButton, 'select__cta');
                            // If option is selected set the element active and change the label in the DummyInput
                            if(opt.selected){
                              addClass(selectOptionButton, selectContentListItemActiveState);
                              selectDummyInput.innerHTML = label;
                              selectOptionButton.setAttribute('aria-selected', true);
                            }else{
                              selectOptionButton.setAttribute('aria-selected', false);
                            }

                            var closestOptGroup = opt.closest('optgroup');

                            selectOptionButton.setAttribute('type', 'button');
                            selectOptionButton.setAttribute('data-index', i);
                            selectOptionButton.setAttribute('data-value', value);
                            selectOptionButton.setAttribute('data-label', label);
                            selectOptionButton.setAttribute('data-record','');
                            selectOptionButton.setAttribute('data-focus', '');
                            selectOptionButton.setAttribute('role', 'option');
                            selectOptionButton.setAttribute('tabindex','-1');

                            // Titel (span wrapper)
                            var selectOptionTitleSpan = document.createElement("span");
                                addClass(selectOptionTitleSpan, 'select__cta__title');
                                selectOptionTitleSpan.innerHTML = label;

                                // Appends
                                selectOptionButton.appendChild(selectOptionTitleSpan);
                            selectOption.appendChild(selectOptionButton);

                        // Assign to option group if available
                        if(closestOptGroup !== null){
                          var closestGeneratedOptGroup = selectList.querySelector('[data-label="' + closestOptGroup.getAttribute('label') + '"]')
                          closestGeneratedOptGroup.appendChild(selectOption);
                        }else{
                          selectList.appendChild(selectOption);
                        }

                        // Add to arrOptions array
                        arr.push(label);
                        i++;
                      }
                    });

      return [arr, uniqId, selectContainer, selectList];
  };

  /*
  * setNoResultsFoundElement()
  * Generate the "no results found" option
  */
  function setNoResultsFoundElement(state, selectField, selectContentList, params){

    switch(state){
      case "show":
        var prevEl = selectContentList.querySelector('[data-empty]');

        if(prevEl == null){
          var noResultsFoundElement = document.createElement('div');
              addClass(noResultsFoundElement, "select__item");

              selectContentList.appendChild(noResultsFoundElement);

              var noResultsFoundElementContent = document.createElement('div');
                  addClass(noResultsFoundElementContent, 'select__empty');
                  noResultsFoundElementContent.setAttribute('data-empty', '');

                  if(params !== undefined && params.noResultsFound !== undefined && params.noResultsFound.title !== undefined){
                    noResultsFoundElementContent.innerHTML = params.noResultsFound.title;
                  }else{
                    noResultsFoundElementContent.innerHTML = dfNoResultsFound;
                  }

                  noResultsFoundElement.appendChild(noResultsFoundElementContent);
        }
      break;

      case "hide":
        var prevEl = selectContentList.querySelector('[data-empty]');
        if(prevEl !== null){
          removeElement(prevEl);
        }
      break;
    }
  };

  /*
  * setOriginalSelectFieldOption()
  * Setting the option in the hidden select field equal to the element selected in the generated select
  */
  function setOriginalSelectFieldOption(selectId, val){

    var sel = document.querySelector('.js-select[data-id="'+selectId+'"] select');
    for(var opt, j = 0; opt = sel.options[j]; j++) {
      if(opt.value == val) {
          sel.selectedIndex = j;
          return opt.label;
          break;
      }
    }
  };

})();
