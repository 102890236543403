/**
 * Simulate float-right functionality for a col element
 */

vl.colFloatRight;
(function () {

  if(!Modernizr.flexbox){
    return;
  }

  var elements = document.getElementsByClassName('js-col-float-right');

  vl.colFloatRight = function (col) {
    var colRect = col.getBoundingClientRect();
    // the intersecting col elements
    // get all col elements
    var cols = document.querySelectorAll('.grid > *');
    [].forEach.call(cols, function (element) {
      // check if the element is not inside a floating col
      var parent = element.closest('.js-col-float-right');
      var rect = element.getBoundingClientRect();
      if(element !== col && parent == null && rect.right > colRect.left && rect.top < colRect.bottom && rect.bottom > colRect.top){
        // add margin to the parent
        var grid = element.closest('.grid');
        var margin = colRect.bottom - rect.top;
        grid.style.marginTop = parseInt(margin)+'px';
        addClass(grid, 'js-col-float-right--pushed');
      }
    });
  };


  if(vl.breakpoint.value !== 'small' && vl.breakpoint.value !== 'xsmall') {
    [].forEach.call(elements, function (element) {
      vl.colFloatRight(element);
    });
  }

  /**
   * Add eventlisteners to window
   */
  window.addEventListener('resize', function() {
    if(vl.breakpoint.value !== 'small' && vl.breakpoint.value !== 'xsmall') {
      [].forEach.call(elements, function (element) {
        vl.colFloatRight(element);
      });
    }
  });

})();
